export const insertScript = (src: string, id?: string) => {
  if (id && document.getElementById(id)) {
    return
  }

  const trackingScript = document.createElement('script')

  trackingScript.async = true
  trackingScript.src = src

  if (id) {
    trackingScript.id = id
  }

  const loaded = new Promise<void>((res) => {
    const cb = () => {
      res()

      trackingScript.removeEventListener('load', cb)
    }

    trackingScript.addEventListener('load', cb)
  })

  const firstScript = document.getElementsByTagName('script')[0]
  if (firstScript && firstScript.parentNode) {
    firstScript.parentNode.insertBefore(trackingScript, firstScript)
  } else {
    document.body.append(trackingScript)
  }

  return loaded
}

export const documentLoaded = (state: DocumentReadyState | DocumentReadyState[]) => {
  return new Promise<void>(res => {
    if (state === document.readyState || state.includes(document.readyState)) {
      res()

      return
    }

    const check = () => {
      if (state === document.readyState || state.includes(document.readyState)) {
        res()

        document.removeEventListener("readystatechange", check)
      }
    }

    document.addEventListener("readystatechange", check)
  })
}

export const waitFor = (ms: number) => {
  return new Promise(res => setTimeout(res, ms))
}
